.contact-main {
  text-align: center;
  padding: 0px 0px 99px 0px;
  .title-project {
    padding-bottom: 30px;
  }
  p {
    font-size: $font-size-secondary;
    padding-bottom: 40px;
  }
  .main-contact {
    display: flex;
    padding: 28px 0px 0px 0px;

    @media screen and (max-width: 850px) {
      display: block;
      box-shadow: none;
      // background-color: $color-white;
    }

    /**CARD CONTACT FORM**/
    .contact-form {
      &.width50 {
        width: 50%;
        display: flex;
        flex-direction: column;
        background-color: $color-bg-card;
        border-radius: 8px;
        box-shadow: 0px 0px 10px $color-box-shadow;

        @media screen and (max-width: 850px) {
          width: 100%;
          box-shadow: 0px 0px 10px $color-box-shadow;
        }
      }
      .form-content {
        width: 80%;
        align-self: center;
        padding-top: 15px;

        @media screen and (max-width: 850px) {
          width: 90%;
        }
        input,
        textarea {
          background: none;
          font-size: $font-size;
          padding: 16px;
          width: 100%;
          margin: 15px 0px;
          border-radius: 8px;
          border: 1px solid $color-input-border;
          box-shadow: 0px 0px 10px $color-box-shadow;
          background-color: $color-input-bg;

          @media screen and (max-width: 850px) {
            &:nth-child(1) {
              margin-top: 30px;
            }
          }
          &:hover {
            background-color: $color-input-hover-bg;
            border: 1px solid $color-input-hover-border;
            font-weight: $font-weight-bold;
          }
        }

        .email-content {
          width: 100%;
          position: relative;
          #not-mail {
            display: none;
            position: absolute;
            top: 32px;
            color: $color-error;
            right: 15px;
            transform-origin: 50% 50%;
          }
          @keyframes dongle {
            0% {
              transform: translate(0px, 0px);
            }
            10% {
              transform: translate(-10px, 0px);
            }
            20% {
              transform: translate(10px, 0px);
            }
            30% {
              transform: translate(-10px, 0px);
            }
            40% {
              transform: translate(10px, 0px);
            }
            50% {
              transform: translate(-10px, 0px);
            }
            60% {
              transform: translate(10px, 0px);
            }
            70% {
              transform: translate(-10px, 0px);
            }
            80% {
              transform: translate(10px, 0px);
            }
            90% {
              transform: translate(-10px, 0px);
            }
            100% {
              transform: translate(0px, 0px);
            }
          }
          input {
            border: 1px solid $color-input-border;
            &:hover {
              background-color: $color-input-hover-bg;
              border: 1px solid $color-input-hover-border;
            }
          }
        }
      }
      .error::placeholder {
        color: $color-error;
      }
      .form-message {
        // position: absolute;
        padding: 15px;
        opacity: 0;
        background: $color-success;
        transition: 0.4s ease;
        color: $color-white;
        border-radius: 8px;
        box-shadow: 0 0 2px $color-box-shadow-secondary;
        top: 315px;
        width: 80%;
        align-self: center;

        @media screen and (max-width: 850px) {
          margin-bottom: 15px;
          width: 90%;
        }
      }
      .button {
        width: 80%;
        // margin-bottom: 30px;
        padding: 20px 60px;
        align-self: center;
        font-size: $font-size;
        background-color: $color-button-bg;
        border-radius: 8px;
        color: $color-button;
        cursor: pointer;

        @media screen and (max-width: 850px) {
          margin-bottom: 15px;
          width: 90%;
        }
        &:hover {
          background-color: $color-button-bg-hover;
        }
      }
    }

    /**CARD INFO**/
    .info-form {
      align-self: center;
      margin-left: auto;
      margin-right: auto;
      box-shadow: 0px 0px 10px $color-box-shadow;
      background-color: $color-white;
      padding: 30px 20px;
      border-radius: 8px;

      @media screen and (max-width: 850px) {
        margin-top: 60px;
        padding-left: 60px;
        box-shadow: 0px 0px 10px $color-box-shadow;
        padding: 0px 15%;
      }
      .address,
      .phone,
      .email {
        display: flex;
        padding: 20px 0px;
        &:nth-child(1) {
          padding-top: 50px;
        }
        .img-content {
          width: 40px;
          height: auto;
          img {
            width: 35px;
          }
        }
        .info-content {
          padding-left: 20px;
          text-align: left;
          h4 {
            font-size: $font-size-h4;
            padding-bottom: 10px;
            color: $color-progressBar;
          }
          .hover {
            &:hover {
              color: $color-hover-link;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
