footer {
  padding-bottom: 45px;
  .social-network {
    font-size: $font-size-social-network;
    text-align: center;

    ul {
      display: flex;
      justify-content: center;

      @media screen and (max-width: 450px) {
        min-width: 100%;
      }

      a {
        padding: 0px 40px;
        transition: 1.1s ease-out;
      }
    }
  }
}
