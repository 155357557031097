.dynamic-text {
  position: relative;
  display: flex;

  .presentation {
    color: $color-dynamic-text-2;

    @media screen and (max-width: 1305px) {
      width: 175px;
    }

    @media screen and (max-width: 850px) {
      width: 100px;
    }
  }

  #text-target {
    display: flex;
    width: 470px;
    text-align: left;
    animation: color 30s alternate infinite;
    perspective: 200px;
    transform: translateX(26px);

    @media screen and (max-width: 1305px) {
      width: 468px;
    }

    @media screen and (max-width: 850px) {
      width: 223px;
    }
  }
  .word {
    position: absolute;
  }
  @keyframes color {
    0% {
      color: $color-dynamic-text-1;
    }
    50% {
      color: $color-dynamic-text-2;
    }
    100% {
      color: $color-dynamic-text-1;
    }
  }
  .letter {
    transform: translateZ(200px);
  }
  @keyframes anim {
    25% {
      transform: translateZ(0px);
      opacity: 1;
    }
    32% {
      opacity: 0;
      transform: translateZ(-280px) translateX(200px);
    }
  }
}
