.navigation {
  font-size: $font-size-menu-navigation;
  align-self: center;
  .nav-active {
    color: $color-hover-green-text;
  }
  ul {
    display: flex;
    justify-content: flex-end;
    .icon-home,
    .icon-skill,
    .icon-portfolio,
    .icon-contact {
      width: 30px;
      display: none;
      margin: 15px 20px;
      &:hover {
        fill: goldenrod;
      }

      @media screen and (max-width: 850px) {
        display: block;
      }
    }
    li {
      margin: 15px 15px;
      position: relative;
      display: block;
      transition: 0.5s;
      &::after {
        position: absolute;
        content: '';
        top: 115%;
        left: 0px;
        width: 100%;
        height: 3px;
        background: $color-hover-green-text;
        transform: scaleX(0);
        transform-origin: right;
        transition: transform 0.5s;
      }
      &:hover::after {
        transform: scaleX(1);
        transform-origin: left;
      }
      &.current:after {
        transform: scaleX(1);
      }

      @media screen and (max-width: 850px) {
        display: none;
      }
    }
  }
  ul:hover li:not(:hover) {
    opacity: 0.5;
  }
  ul:hover li:hover {
    color: $color-hover-green-text;
  }
}
