@font-face {
  font-family: 'font-1';
  src: url(../assets/fonts/Questrial-Regular.ttf);
}
@font-face {
  font-family: 'font-2';
  src: url(../assets/fonts/KronaOne-Regular.ttf);
}

/* Colors */
$color-white: #fff;
$color-black: #000;
$color-black-pale: #262725;
$color-success: #59a859;
$color-title: rgb(0, 128, 0);
$color-error: rgb(253, 87, 87);
$color-grey-pale: rgb(218, 213, 213);
$color-grey-medium: rgb(192, 189, 189);
$color-hover-green-text: #59a859;
$color-hover-link: rgb(175, 174, 174);
$color-blue-pale: rgb(2, 121, 148);

$color-dynamic-text-1: #59a859;
$color-dynamic-text-2: rgb(189, 187, 187);

$color-progressBar: #278603;

$color-bg-logo: hsla(136, 58%, 85%, 0.5);
$color-bg-card: #e2fbdd;

$color-input-bg: #fff;
$color-input-border: rgb(255, 255, 255);
$color-input-hover-border: rgb(114, 186, 245);
$color-input-hover-bg: rgb(218, 237, 252);

$color-box-shadow: hsla(232, 39%, 72%, 0.5);
$color-box-shadow-secondary: rgba(51, 51, 51, 0.3);

$color-button: #fff;
$color-button-bg: rgb(38, 39, 37);
$color-button-bg-hover: rgb(82, 80, 81);

/* font-family */
$font-family: 'font-1', sans-serif;
$font-family-secondary: 'font-2', serif;

/* font-size */
$font-size-XX-size: 14px;
$font-size: 16px;
$font-size-secondary: 20px;
$font-size-h1: 50px;
$font-size-h2: 24px;
$font-size-h3: 30px;
$font-size-h4: 20px;
$font-weight: 100;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

$font-size-social-network: 20px;
$font-size-menu-navigation: 22px;
