body {
  transition: 0.2s linear;
  .toggle {
    align-self: center;
    padding-top: 20px;
    .checkbox {
      display: none;
    }
    .label {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 50px;
      height: 26px;
      background-color: $color-black-pale;
      padding: 5px 8px;
      border-radius: 50px;
      cursor: pointer;
      transform: scale(1.2);
      .fa-moon {
        color: rgb(252, 189, 53);
      }
      .fa-sun {
        color: rgb(194, 191, 3);
      }
      .ball {
        position: absolute;
        background: $color-white;
        border-radius: 50px;
        width: 22px;
        height: 22px;
        left: 2px;
        transform: translateX(0px);
        transition: transform 0.2s linear;
      }
    }
    .checkbox:checked + .label .ball {
      transform: translateX(24px);
    }
  }
}

/***DARK MODE**/

.dark {
  background-color: $color-black-pale;
  .bg-header {
    background-color: $color-white;
    border-top: 1px solid $color-black-pale;
  }

  /***Home Mode**/
  .toggle .label {
    background-color: $color-white;
  }
  .toggle .label .ball {
    background: $color-black-pale;
  }
  .home .home-main .main-content h1 {
    color: $color-white;
  }
  .home .home-main .main-content .wrapper-link .portfolio {
    color: $color-white;
  }
  .home .home-main .main-content .wrapper-link .pdf:hover {
    color: $color-white;
  }

  /***Knowledge Mode**/
  .main-knowledge .languages-container {
    background-color: $color-white;
  }

  /***Portfolio Mode**/
  .main-project .article-content .aside-content {
    background-color: $color-white;
    color: $color-black-pale;
  }
  .main-project .article-content .aside-content a {
    color: $color-black-pale !important;
  }
  .main-project .article-content .img-content .img-container span {
    color: $color-white;
  }
  .main-project .article-content .aside-content .source {
    color: #000;
    background-color: rgba(89, 155, 105, 0.42);
    &:hover {
      background-color: hsla(134, 27%, 48%, 0);
      transition: 1s;
    }
  }
}
