.home {
  height: 100vh;
  width: 100vw;
  .home-main {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 74%;
    overflow: hidden;

    /**DYNAMIC TEXT**/
    .main-content {
      z-index: 1;
      display: flex;
      flex-direction: column;
      width: 100%;
      h1 {
        font-size: $font-size-h1;
        position: relative;
        font-family: $font-family-secondary;
        color: $color-black-pale;
        align-self: center;

        @media screen and (max-width: 850px) {
          font-size: 25px;
        }

        &:before {
          content: '';
          position: absolute;
          height: 195px;
          width: 195px;
          border-radius: 50%;
          border: 2px $color-hover-green-text solid;
          top: -93px;
          left: -130px;
          z-index: -1;
          animation: h1Anim 1.5s forwards ease;
          transform: scale(0);

          @media screen and (max-width: 850px) {
            height: 90px;
            width: 90px;
            left: -60px;
            top: -40px;
          }

          @keyframes h1Anim {
            to {
              transform: scale(1);
            }
          }
        }
      }
      h2 {
        font-size: 66px;
        font-weight: $font-weight;
        color: $color-grey-pale;
        align-self: center;

        @media screen and (max-width: 850px) {
          font-size: 33px;
        }
      }
      .wrapper-link {
        padding-top: 30px;
        display: flex;
        font-size: $font-size-secondary;
        text-align: center;
        align-self: center;
        cursor: pointer;
        .portfolio {
          width: 220px;
          height: 52px;
          border: 1px solid $color-dynamic-text-1;
          border-radius: 10px;
          color: $color-black-pale;
          font-weight: $font-weight-bold;
          line-height: 2.4;
          transition: background-color 0.4s;
          &:hover {
            color: $color-white;
            background-color: $color-dynamic-text-1;
            transition: background-color 0.5s;
          }

          @media screen and (max-width: 850px) {
            width: 200px;
            height: 50px;
          }
        }
        .pdf {
          width: 220px;
          height: 52px;
          background-color: $color-dynamic-text-1;
          border-radius: 10px;
          margin-left: 30px;
          color: $color-white;
          font-weight: $font-weight-bold;
          line-height: 2.4;
          transition: background-color 0.4s;
          &:hover {
            color: $color-black-pale;
            background-color: transparent;
            transition: background-color 0.5s;
            border: 1px solid $color-dynamic-text-1;
          }

          @media screen and (max-width: 850px) {
            width: 200px;
            height: 50px;
          }
        }
      }
    }
  }
}
.bg-home {
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
