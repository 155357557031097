.main-knowledge {
  display: flex;
  flex-wrap: wrap;
  padding-top: 58px;
  padding-bottom: 100px;
  width: 100%;
  font-size: $font-size;

  @media screen and (max-width: 1305px) {
    display: block;
  }

  /** CARD KNOWLEDGE**/
  .languages-container {
    width: 65%;
    padding: 20px 120px 30px 120px;
    box-shadow: 0px 0px 10px $color-box-shadow;
    border-radius: 10px;
    overflow: hidden;
    position: relative;

    @media screen and (max-width: 1305px) {
      width: 100%;
      padding: 20px 50px 30px 50px;
    }
    .cms-display {
      overflow: hidden;
      position: relative;
      padding-top: 20px;
    }
    .languages-display,
    .cms-display {
      h3 {
        color: $color-progressBar;
        padding: 10px 0px;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
      }
      .years {
        display: flex;
        justify-content: space-between;
        font-size: $font-size-XX-size;
        color: $color-progressBar;
        span {
          &:nth-child(2):before,
          &:nth-child(3):before {
            content: '';
            background-color: $color-progressBar;
            position: absolute;
            height: 390px;
            width: 1px;
            transform: translate(12px, 19px);
          }
        }
      }
      .languages-list {
        padding-top: 10px;
        li {
          color: $color-black;
        }
        .progress-bar {
          height: 12px;
          background: transparent;
          border-radius: 0px 50px 50px 0px;
          margin-top: 3px;
          position: relative;

          &:after {
            content: '';
            position: absolute;
            background: $color-progressBar;
            border-radius: 0px 50px 50px 0px;
            width: 0;
            height: 10px;
            animation: line 3s ease-out forwards;

            @keyframes line {
              to {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  /** CARD OTHER KNOWLEDGE**/
  .skill-container {
    width: 30%;
    padding: 20px 100px 20px 100px;
    box-shadow: 0px 0px 10px $color-box-shadow;
    background-color: $color-bg-card;
    border-radius: 10px;
    margin-left: 5%;

    @media screen and (max-width: 1305px) {
      width: 100%;
      padding: 20px 50px 20px 50px;
      margin-left: 0;
      margin-top: 60px;
    }
    h3 {
      color: #278603;
      padding: 10px 0px;
      font-weight: 700;
      text-transform: uppercase;
    }
    .list {
      ul {
        height: auto;
        li {
          display: flex;
          padding-top: 10px;
          img {
            width: 15px;
          }
          span {
            padding-left: 10px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1305px) {
  .list {
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        padding: 10px;
      }
    }
  }
}
