.main-project {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-top: 58px;
  padding-bottom: 50px;

  /**CARD PROJET**/
  .article-content {
    padding-bottom: 50px;
    a:hover {
      color: $color-black;
    }

    /**Card image **/
    .img-content {
      position: relative;
      text-align: left;

      @media screen and (max-width: 700px) {
        text-align: center;
        margin: 0 auto;
      }
      .img-container {
        width: 402px;
        height: auto;
        overflow: hidden;
        position: relative;

        &:hover span,
        span:hover {
          visibility: visible;
          transform: translateY(0);
        }

        img {
          overflow: hidden;
          object-fit: cover;
          object-position: 0%;
          width: 100%;
          height: 100%;
          position: absolute;
          transition: 0.4s ease-out;
          position: relative;

          &:hover,
          span:hover img {
            transform: scale(1.2);
            filter: opacity(15%) contrast(150%);
          }
        }
        span {
          position: absolute;
          bottom: 20px;
          left: 0;
          width: 100%;
          padding: 1rem;
          margin: 0 auto;
          line-height: 34px;
          font-size: 1.5rem;
          visibility: hidden;
          transition: 0.5s ease;
          transform: translateY(200px);

          h3 {
            font-size: $font-size-h3;
            padding-bottom: 10px;
            color: $color-blue-pale;
            .icon-link-color2 {
              width: 21px;
              height: 21px;
              margin-right: 10px;
            }
          }
          p {
            font-size: $font-size-secondary;
          }
        }
      }
    }

    /**card bottom **/
    .aside-content {
      position: relative;
      display: flex;
      justify-content: space-between;
      padding: 10px 20px;
      background-color: rgb(36, 35, 35);
      color: $color-white;
      a {
        color: $color-white !important;
      }
      a:hover {
        color: $color-white !important;
      }
      h1 {
        font-size: 20px;
        padding: 2px 0px 10px 0px;
        letter-spacing: 3px;
      }
      ul {
        display: flex;
        padding: 2px 0px;
        color: $color-grey-medium;
        li:first-child {
          padding-right: 5px;
        }
        li:nth-child(2),
        li:nth-child(3),
        li:nth-child(4),
        li:nth-child(5),
        li:nth-child(6),
        li:nth-child(7),
        li:nth-child(8) {
          padding: 0px 5px;
        }
      }
      p {
        font-size: $font-size;
        padding: 2px 0px;
        letter-spacing: 1px;
      }
      .source {
        position: absolute;
        color: $color-white;
        font-size: $font-size-XX-size;
        background-color: hsla(134, 27%, 48%, 0.22);
        right: 0;
        top: 0;
        padding: 5px 8px;
        letter-spacing: normal;
        &:hover {
          background-color: hsla(134, 27%, 48%, 0);
          transition: 1s;
        }
        .icon-link-color1 {
          width: 10px;
          height: 10px;
          margin-right: 10px;
        }
      }
    }
  }
}
