* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
  outline: none;
  border: none;
  text-decoration: none;
}
a,
a:visited {
  color: $color-hover-link;
}
a:hover {
  color: $color-hover-green-text;
}
.bg-header {
  background-color: $color-black-pale;
  border-bottom: 4px solid $color-title;
}
.container {
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
}
.flex-content {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
}
