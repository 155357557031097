/** PAGE TITLE **/
.title-project {
  transform: translateX(38%);
  width: 250px;
  h1 {
    background-color: $color-title;
    color: #fff;

    padding: 8px 5px;
    text-align: center;
  }
  @media screen and (max-width: 700px) {
  }
}
